export enum FlowTypes {
  PAY_BY_LINK = "shakira_paylink_checkout",
  BUTTON_CHECKOUT = "shakira_button_checkout",
  EGC = "shakira_expedited_guest_checkout",
  EMBEDDED = "embedded_components",
  GUEST_ENROLLMENT = "shakira_guest_enrollment",
  SHOPPER_DASHBOARD = "shopper_dashboard",
}

export const SKIPIFY_ANALYTICS_CONST = {
  LOCAL_STORAGE_KEY: "asid",
  TTL: 1800000, // 30 min,
};
