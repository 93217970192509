import { CompleteCardResponse, ConfirmCardResponse } from "@models/api/apiResponses";
import {
  CardDuplicates,
  CardLinkageStatus,
  CardLinkageType,
  CardStatus,
  ConsolidatedPaymentMethod,
  LinkedCard,
  ManualCard,
  UnLinkedCard,
  WalletCard,
} from "@models/card";
import {
  completeCardLink,
  confirmCardLink,
  getAllCards,
  deletePaymentMethod,
  confirmCardLinkWithOrderTotal,
} from "@modules/payment/payment-service";
import ampli from "@services/skipifyEvents";
import useOrderStore from "@stores/orderStore";
import { inDevTestLocal } from "@utils/inDevTestLocal";
import axios from "axios";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

export enum CardErrors {
  CREATING_CARD = "confirming your card",
  GETTING_PAYMENT_METHODS = "getting your payment methods",
}

type PaymentStoreNonFunctionalFields = {
  loading: boolean; // until this is refactored: loading means "cards haven't been retrieved"-- for in-flight confirm/complete requests, track elsewhere.
  fetched: boolean;
  unLinkedCards: UnLinkedCard[]; // these are cards that are available for linking but have not yet been linked
  paymentMethods: WalletCard[]; // manual cards and linked CL
  hiddenCards: ConsolidatedPaymentMethod[]; // these are cards that for one reason or another we won't display to the user but that the backend still returns to us
  duplicateCards: CardDuplicates[];
  allCards: ConsolidatedPaymentMethod[];
  issuerRedirectUrl: string;
  cardLinkingInProgress: boolean;
  processPaymentError?: string;
  individualCardLinkingFailure?: CardStatus | undefined;
  error?: CardErrors;
  rawError?: Error;
  selectedPaymentMethod: ConsolidatedPaymentMethod[] | undefined;
  multipleCards: boolean;
  splitEqually: boolean;
  splitPaymentValues: Record<string, number>;
  showErrorOnNoSelectedCard?: boolean;
  errorsByPaymentMethod: Record<string, number>;
};

export type PaymentStore = PaymentStoreNonFunctionalFields & {
  confirmCard: (
    panId: string,
    orderId: string,
    merchantId?: string,
    options?: { useOrderTotal?: boolean; orderTotal?: number; doNotSelectDefaultCard?: boolean },
  ) => Promise<ConfirmCardResponse | undefined>;
  completeCard: (merchantId?: string) => Promise<CompleteCardResponse | undefined>;
  getAllCardsMethods: (
    merchantId?: string,
    options?: { doNotSelectDefaultCard?: boolean },
  ) => Promise<(LinkedCard | ManualCard)[] | undefined>;
  getDuplicatePaymentMethod: (cardId?: string) => ManualCard | undefined;
  getPaymentMethodById: (id: string) => ConsolidatedPaymentMethod | undefined;
  selectPaymentMethodById: (id: string | string[] | undefined) => ConsolidatedPaymentMethod[];
  handleStepUpFailed: (merchantId?: string, options?: { doNotSelectDefaultCard?: boolean }) => void;
  clearIndividualCardLinkingFailure: () => void;
  setProcessPaymentError: (msg?: string) => void;
  setIndividualCardLinkingFailure: (status?: CardStatus) => void;
  setCardError: (err?: CardErrors) => void;
  deletePaymentMethodAndRefresh: (
    cardId: string,
    merchantId?: string,
    options?: { doNotSelectDefaultCard?: boolean },
  ) => Promise<void>;
  toggleMultipleCards: (value: boolean) => void;
  toggleSplitEqually: (value: boolean) => void;
  addSplitPaymentValue: (card_id: string, amount: number) => void;
  removeSplitPaymentValue: (card_id: string) => void;
  setSplitPaymentValue: (values: Record<string, number>) => void;
  setSelectedPaymentMethod: (cardId: string) => void;
  setShowErrorOnNoSelectedCard: (value: boolean) => void;
  updateErrorsByPaymentMethod: () => void;
  reset: () => void;
};

const initialState: PaymentStoreNonFunctionalFields = {
  loading: false,
  fetched: false,
  unLinkedCards: [],
  paymentMethods: [],
  hiddenCards: [],
  duplicateCards: [],
  allCards: [],
  issuerRedirectUrl: "",
  cardLinkingInProgress: false,
  individualCardLinkingFailure: undefined,
  selectedPaymentMethod: undefined,
  multipleCards: false,
  splitEqually: false,
  splitPaymentValues: {},
  error: undefined,
  rawError: undefined,
  errorsByPaymentMethod: {},
};

function dedupPaymentMethods(wallet: (LinkedCard | ManualCard)[] | undefined) {
  let erroredCLcard = false;

  if (!wallet) {
    return { paymentMethods: [], duplicateCards: [], defaultCard: undefined };
  }
  let defaultCard = wallet.find((c) => c.is_default);
  const paymentMethods: (LinkedCard | ManualCard)[] = [];
  const duplicateCards: CardDuplicates[] = [];
  wallet.map((p) => {
    if (
      defaultCard &&
      !(defaultCard.card_linkage_type === CardLinkageType.LinkedCard) &&
      p.card_id === defaultCard.duplicate_of_card_id
    ) {
      // this is a card-linked duplicate of the manual default set the CL duplicate as default
      p.is_default = true;
      defaultCard = p;
    }
    // sets a boolean letting us know we will try and replace with a manual duplicate later
    if (p.card_status === CardStatus.temporaryFailure && p.duplicate_of_card_id) erroredCLcard = true;
    // if a card is a manual user card and a duplicate of a CL card separate it out of the paymentMethods
    if (p.card_linkage_type === CardLinkageType.UserCard && p.duplicate_of_card_id) {
      // Check the duplicates card array for a record with the duplicate already exists (this would be the CL card id)
      const bucket = duplicateCards.find((it) => it.card_id === p.duplicate_of_card_id);
      if (bucket) {
        // If the CL card already has a duplicate, add another to its duplicates array
        bucket.duplicates.push(p);
      } else {
        // Add the new record to the duplicate cards array
        duplicateCards.push({ card_id: p.duplicate_of_card_id, duplicates: [p] });
      }
    } else {
      // No duplicates detected, push onto paymentMethods
      paymentMethods.push(p);
    }
  });

  // iterate over paymentMethods and replace errored CL card (TEMPORARY_FAILURE) with an unerrored duplicate -- if it exists
  if (erroredCLcard) {
    for (let i = 0; i < paymentMethods.length; i++) {
      const card = paymentMethods[i];
      if (
        card.card_linkage_type === CardLinkageType.LinkedCard &&
        card.duplicate_of_card_id &&
        card.card_status === CardStatus.temporaryFailure
      ) {
        duplicateCards.forEach((cardDuplicates) => {
          if (cardDuplicates.card_id === card.card_id) {
            // we are now looking at the array of duplicates for the CL card with Id card.card_id
            for (let j = 0; j < cardDuplicates.duplicates.length; j++) {
              const potentialManualDuplicate = cardDuplicates.duplicates[j];

              // we only want to replace with an unerrored manual duplicate
              if (potentialManualDuplicate && !potentialManualDuplicate?.card_status) {
                paymentMethods[i] = potentialManualDuplicate;
                if (card.is_default) defaultCard = potentialManualDuplicate;
              }
            }
          }
        });
      }
    }
  }

  return { paymentMethods, duplicateCards, defaultCard };
}

const usePaymentStore = create<PaymentStore>()(
  devtools(
    (set, get) => ({
      ...initialState,
      // this function is to be called after actions that may change the availability of cards ex. confirmCard.
      // it can optionally take in an id (card_id or card_metadata_id) to try and set as selected
      getAllCardsMethods: async (merchantId?: string, options?: { doNotSelectDefaultCard?: boolean }) => {
        set({ loading: true });
        try {
          // data contains CL unlinked cards, linked CL cards, and manual cards
          const { data } = await getAllCards(merchantId);
          if (!data) {
            throw new Error("[getAllCardsMethods] ERROR: No cards received from all_cards.");
          }
          const { unLinkedCards, linkedAndManualCards, hiddenCards } = data.cards.reduce<{
            unLinkedCards: UnLinkedCard[];
            linkedAndManualCards: (LinkedCard | ManualCard)[];
            hiddenCards: ConsolidatedPaymentMethod[];
          }>(
            ({ unLinkedCards, linkedAndManualCards, hiddenCards }, card) => {
              card.network_type = card.network_type.toLowerCase();
              if (card.card_status === CardStatus.notMerchantAccepted && !card.is_default) {
                // we only want to show the user a card that isn't accepted by the merchant if it is their default card
                hiddenCards.push(card);
              } else if (card.card_linkage_type === CardLinkageType.UnlinkedCard) {
                unLinkedCards.push(card);
              } else if (
                card.card_linkage_type === CardLinkageType.LinkedCard ||
                card.card_linkage_type === CardLinkageType.UserCard
              ) {
                linkedAndManualCards.push(card);
              } else {
                //TODO maybe warning a no card should not have been covered by previous checks.
              }
              return { unLinkedCards, linkedAndManualCards, hiddenCards };
            },
            { unLinkedCards: [], linkedAndManualCards: [], hiddenCards: [] },
          );
          const { paymentMethods, duplicateCards, defaultCard } = dedupPaymentMethods(linkedAndManualCards);
          set({
            allCards: data?.cards,
            paymentMethods,
            duplicateCards,
            unLinkedCards,
            hiddenCards,
            error: undefined,
            rawError: undefined,
          });
          // set selectedPaymentMethod to the current (incoming) version of an already selected card or
          // if no payment method is currently selected, set to the default card or the first card in paymentMethods
          const currentlySelected = get().selectedPaymentMethod;
          if (currentlySelected && currentlySelected.length) {
            const ids = currentlySelected.map((card) => {
              if (card.card_linkage_type === CardLinkageType.UserCard) {
                return card.card_id;
              }
              return card.card_metadata_id;
            });
            get().selectPaymentMethodById(ids);
          } else if (!options?.doNotSelectDefaultCard) {
            // make sure we don't select manual cards with "inactive" status
            const activePaymentMethods = paymentMethods.filter(
              (card) => !(card.card_linkage_type === CardLinkageType.UserCard && card.status === "inactive"),
            );

            const isDefaultCardInactive =
              defaultCard?.card_linkage_type === CardLinkageType.UserCard && defaultCard?.status === "inactive";

            const cardToMakeSelected = defaultCard && !isDefaultCardInactive ? defaultCard : activePaymentMethods[0];

            set({
              selectedPaymentMethod: cardToMakeSelected ? [cardToMakeSelected] : [],
              individualCardLinkingFailure: cardToMakeSelected
                ? cardToMakeSelected.card_status
                : get().individualCardLinkingFailure, // Keep the current value if we don't have a new card selected
            });
          }
          //PaymentMethods is an empty array return undefined?
          if (Array.isArray(paymentMethods) && paymentMethods.length === 0) return undefined;
          return paymentMethods;
        } catch (err) {
          console.log(err);
          set({ error: CardErrors.GETTING_PAYMENT_METHODS, rawError: err as Error });
        } finally {
          set({ loading: false, fetched: true });
        }
      },
      confirmCard: async (panId: string, orderId: string, merchantId = "", options) => {
        set({ cardLinkingInProgress: true });
        try {
          // Is this selectPaymentMethod necessary? I think not - this breaks split Payments because it selects a card and deletes current selection
          // await get().selectPaymentMethodById(panId);
          // the card that is selected can either be a linked CL card (found in the paymentMethod array) or unlinked and in the unLinkedCards array
          // this piece finds the issuerId of the selected card
          ampli.track("fe_cl_confirm_pan_requested");
          const cardToConfirm = get().getPaymentMethodById(panId);
          if (!cardToConfirm) {
            throw new Error("[confirm] id not found in the card linking list " + panId);
          }
          // type narrowing we shouldn't be confirming a manual card
          if (cardToConfirm.card_linkage_type === CardLinkageType.UserCard) {
            throw new Error("[confirm] tried to confirm manual card " + panId);
          }
          // we shouldn't be confirming a card that doesn't need confirming
          if (
            cardToConfirm.card_linkage_type !== CardLinkageType.UnlinkedCard &&
            !(cardToConfirm.card_linkage_status === CardLinkageStatus.NeedsConfirm)
          ) {
            throw new Error(
              "[confirm] tried to confirm a card that did not have needsConfirm " +
                panId +
                cardToConfirm.card_linkage_status,
            );
          }

          let data: ConfirmCardResponse | undefined;

          // data will either be CardLinkingData -- indicating that the card was successfully linked or RedirectResponse indicating stepup
          if (options && options.useOrderTotal && typeof options.orderTotal !== "undefined" && options.orderTotal > 0) {
            const resp = await confirmCardLinkWithOrderTotal(panId, cardToConfirm.issuer_id, options.orderTotal);
            data = resp.data;
          } else {
            const resp = await confirmCardLink(panId, cardToConfirm.issuer_id, orderId);
            data = resp.data;
          }

          if (!data) {
            throw new Error("[confirm] ERROR: No card received from card linking API.");
          }
          ampli.track("fe_cl_confirm_pan_success");
          if (data?.redirect_issuer_url) {
            set({ issuerRedirectUrl: data.redirect_issuer_url });
          } else {
            // confirming a card may have changed the state of cards in CL service. cards are refetched and the confirmed card selected.
            await get().getAllCardsMethods(merchantId, { doNotSelectDefaultCard: options?.doNotSelectDefaultCard });

            const linkedCard = get().paymentMethods.find(
              (card) =>
                card.card_linkage_type === CardLinkageType.LinkedCard &&
                card.card_metadata_id === data.card_metadata_id,
            );
            const filterOutLinkedCard = (c: ConsolidatedPaymentMethod) =>
              "card_id" in c && c.card_id !== linkedCard?.card_id;

            // no redirect needed, don't call complete cards after this
            set({
              selectedPaymentMethod: [
                ...(get().selectedPaymentMethod?.filter(filterOutLinkedCard) ?? []),
                //TODO PP-2174b Revise type casting here
                //SPLIT PAYMENTS COMMENT< WAS THIS REALLY NECESSARY?
                linkedCard as unknown as ConsolidatedPaymentMethod,
              ],
            });
          }
          return data;
        } catch (e) {
          ampli.track("fe_cl_confirm_pan_fail");
          // this unsets the selectedPaymentMethod so that the next call to all_cards with set it from what is now available
          set({
            selectedPaymentMethod: undefined,
            cardLinkingInProgress: false,
          });
          // refetch cards after failure and set selectedPaymentMethod to what is available
          await getAllCards(merchantId);
        }
      },
      // the completeCard function should be called after successful 3CSC
      completeCard: async (merchantId = "") => {
        if (!get().issuerRedirectUrl) return;
        try {
          ampli.track("fe_cl_complete_card_requested");
          const { data } = await completeCardLink();
          if (!data) {
            throw new Error("Card linking API did not complete card");
          }
          ampli.track("fe_cl_complete_card_success");

          // completing a card may have changed the state of cards in CL service. cards are refetched and the confirmed card selected.
          await get().getAllCardsMethods(merchantId);
          set({
            cardLinkingInProgress: false,
            issuerRedirectUrl: "",
          });
          return data;
        } catch (e) {
          // there will already be a card set as selected from the preceding confirmCard call. That is now the card that has failed on completeCard
          const selectedPaymentMethod = get().selectedPaymentMethod;

          // Get the card_linked one ->
          const cardLinkedIdx = selectedPaymentMethod?.findIndex((card) => {
            return "card_linked" in card && card.card_linked === true;
          });
          const card_brand = selectedPaymentMethod?.[cardLinkedIdx || 0]?.network_type;
          const error_code = axios.isAxiosError(e) ? e.response?.statusText || e.code : undefined;
          ampli.track("fe_cl_complete_card_fail", { card_brand, error_code });
          // this unsets the selectedPaymentMethod so that the next call to all_cards will set it from what cards are now available
          set({
            selectedPaymentMethod: undefined,
          });
          // refetch cards after failure and set selectedPaymentMethod to what is available
          await get().getAllCardsMethods(merchantId);
        }
      },
      // returns a payment method by id
      getPaymentMethodById: (id: string) => {
        // The id that comes in could be either a card_id or a card_metadata_id. card_metadata_id might correspond to either an UnLinked card or a Linked card
        const foundPaymentMethod =
          get().paymentMethods.find((p) => {
            if (p.card_id === id) {
              return p;
            }
            if (p.card_linkage_type === CardLinkageType.LinkedCard && p.card_metadata_id === id) {
              return p;
            }
          }) || get().unLinkedCards.find((u) => u.card_metadata_id === id);

        // If we didn't find a payment method, check if its a duplicate and return the correct version
        if (!foundPaymentMethod) {
          const duplicateCardId = get().allCards.find((c) => {
            if (c.card_linkage_type === CardLinkageType.UserCard) {
              return c.card_id === id;
            }
          })?.duplicate_of_card_id;
          if (duplicateCardId) {
            // recursively call this with the duplicate card id
            return get().getPaymentMethodById(duplicateCardId);
          }
          console.warn("[Payment Store] cannot find payment method with id", id);
          return;
        }

        return foundPaymentMethod;
      },
      // Finds a payment method by id and sets it to selectedPaymentMethod in state this is needed because on some external calls all you get back is an id
      selectPaymentMethodById: (id: string | string[] | undefined) => {
        if (!id) {
          set({ selectedPaymentMethod: undefined, individualCardLinkingFailure: undefined });
          return [];
        }

        // Normalize input to always be an array.
        const idsArray = Array.isArray(id) ? id : [id];

        // id could be card metadata id for an unlinked card
        set((state) => {
          //First get all paymentMethodsbyID instead of 1

          const paymentMethods = idsArray.map((id) => state.getPaymentMethodById(id)).filter((pm) => pm !== undefined);
          // Then Check failiure for all cards
          const individualCardLinkingFailures = paymentMethods.map((method) => {
            if (method) {
              const { card_linkage_type, card_status } = method;
              if (
                card_linkage_type === CardLinkageType.LinkedCard ||
                card_linkage_type === CardLinkageType.UnlinkedCard
              ) {
                return card_status;
              }
            }
            return undefined;
          });

          return {
            // Casting because we got rid of undefined at the filter(pm)
            selectedPaymentMethod: paymentMethods as ConsolidatedPaymentMethod[],
            individualCardLinkingFailure: individualCardLinkingFailures.find((s) => s !== undefined),
          };
        });

        return get().selectedPaymentMethod ?? [];
      },
      getCurrentPaymentMethod: () => {
        const cardId = useOrderStore.getState().order?.payment?.ref;
        if (!cardId) return undefined;
        return (
          get().paymentMethods.find((payment) => payment.card_id === cardId) ??
          get().unLinkedCards.find((card) => card.card_metadata_id === cardId) ??
          get().getDuplicatePaymentMethod(cardId)
        );
      },
      getDuplicatePaymentMethod: (cardId?: string) => {
        if (!cardId) return undefined;
        return get()
          .duplicateCards.find((c) => cardId === c.card_id)
          ?.duplicates?.pop(); // TODO erik: maybe keep track of cards and iterate, instead of just using the last one - or would those calls take too long?
      },
      // confirmcard and completeCard are ways the CL can fail this function is needed for when the failure occurs after step up, where confirm card doesn't have visibility.
      // this function should no longer be needed once we are able to communicate stepup failures back to CL service
      handleStepUpFailed: (merchantId, options) => {
        set({ issuerRedirectUrl: "" });
        get().getAllCardsMethods(merchantId, options);
      },
      clearIndividualCardLinkingFailure: () => {
        set({ individualCardLinkingFailure: undefined });
      },
      setProcessPaymentError(msg) {
        set({ processPaymentError: msg || undefined });
      },
      setIndividualCardLinkingFailure: (status) => {
        set({ individualCardLinkingFailure: status });
      },
      setCardError(error) {
        set({ error });
      },
      toggleMultipleCards(value: boolean) {
        set({ multipleCards: value });
      },
      toggleSplitEqually(value: boolean) {
        set({ splitEqually: value });
      },
      addSplitPaymentValue(cardId, value) {
        set((state) => {
          const updatedValues = { ...state.splitPaymentValues };
          // Update or add the card with the new value
          updatedValues[cardId] = value;
          return { splitPaymentValues: updatedValues };
        });
      },
      removeSplitPaymentValue(cardId) {
        set((state) => {
          const updatedValues = { ...state.splitPaymentValues };
          delete updatedValues[cardId];
          return { splitPaymentValues: updatedValues };
        });
      },
      setSplitPaymentValue(values: Record<string, number>) {
        set({ splitPaymentValues: values });
      },
      deletePaymentMethodAndRefresh: async (cardId, merchantId, options?: { doNotSelectDefaultCard?: boolean }) => {
        try {
          // attempt to delete the card
          ampli.track("fe_delete_payment_method");
          await deletePaymentMethod(cardId, merchantId);
          ampli.track("fe_payment_method_deleted_success");
        } catch {
          console.warn("[Payment Store] delete payment method unsuccessful with id", cardId);
        } finally {
          // unset the selected card
          set({ selectedPaymentMethod: undefined });
          // refresh the payment methods
          await get().getAllCardsMethods(merchantId, options);
        }
      },
      setSelectedPaymentMethod: (cardId) => {
        const selectedPaymentMethod = get().getPaymentMethodById(cardId);
        if (selectedPaymentMethod) {
          set({ selectedPaymentMethod: [selectedPaymentMethod] });
          set({ showErrorOnNoSelectedCard: false });
        }
      },
      reset: () => set(initialState),
      setShowErrorOnNoSelectedCard(value: boolean) {
        set({ showErrorOnNoSelectedCard: value });
      },
      updateErrorsByPaymentMethod: () => {
        set((state) => {
          const payments = state.selectedPaymentMethod as WalletCard[];
          return {
            errorsByPaymentMethod: payments?.reduce((acc, payment) => {
              if (payment.card_id) {
                acc[payment.card_id] = (acc[payment.card_id] || 0) + 1;
              }
              return acc;
            }, state.errorsByPaymentMethod),
          };
        });
      },
    }),
    { enabled: inDevTestLocal, name: "paymentStore" },
  ),
);

export function useCard(id?: string) {
  return usePaymentStore((state) => state.paymentMethods.find((c) => c.card_id === id));
}

export default usePaymentStore;
