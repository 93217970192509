export enum CardTypes {
  Visa = "visa",
  Mastercard = "mastercard",
  Amex = "amex",
  Paypal = "paypal",
  Alipay = "alipay",
  Unionpay = "unionpay",
  Discover = "discover",
  Generic = "generic",
  JCB = "jcb",
  DinersClub = "diners club",
}

export enum CardSize {
  Large = "large",
  Medium = "medium",
  Small = "small",
}

export const STATIC_CARD_PREFIX = "/static/card/";

export const defaultCardArtFilename = new Map<string, string>([
  [CardTypes.Visa, "visa.svg"],
  [CardTypes.Mastercard, "mastercard.svg"],
  [CardTypes.Amex, "amex.svg"],
  [CardTypes.Paypal, "paypal.svg"],
  [CardTypes.Alipay, "alipay.svg"],
  [CardTypes.Unionpay, "unionpay.svg"],
  [CardTypes.Discover, "discover.svg"],
  [CardTypes.Generic, "generic.svg"],
  [CardTypes.JCB, "jcb.svg"],
  [CardTypes.DinersClub, "diners.svg"],
]);

export const MAX_PAYMENT_ATTEMPTS_PER_PAYMENT_METHOD = 5;
