import { Grid, Link, Stack, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import PoweredBySkipifyIcon from "@icons/PoweredBySkipifyIcon";

export interface FooterProps {
  dataTestid?: string;
  footerHeight?: number;
  footerBlockTopMargin?: number;
}

export default function Footer({
  dataTestid,
  footerHeight = 40,
  footerBlockTopMargin = 0,
}: PropsWithChildren<FooterProps>) {
  return (
    <footer data-testid={dataTestid || "footer-embedded-components"}>
      <Stack
        direction="column"
        height={footerHeight}
        pt={footerBlockTopMargin}
        pl={2}
        pr={2}
        sx={{ borderTop: "solid .5px", borderTopColor: "primary.light" }}
      >
        <Grid item xs={12} my={1} sx={{ height: "100%" }}>
          <Stack direction="row" justifyContent="space-between" sx={{ height: "100%" }}>
            <PoweredBySkipifyIcon style={{ margin: "auto 0", height: "10px" }} />
            <Stack direction="row" justifyContent="right" fontSize="10px" fontWeight="bold" sx={{ height: "100%" }}>
              <Link
                href="https://www.skipify.com/us/terms-and-conditions/"
                target="_blank"
                sx={{ textDecoration: "none", mt: "auto", mb: "auto" }}
                color="text.primary"
                fontSize="10"
              >
                Terms
              </Link>
              <Typography sx={{ mt: "auto", mb: "auto" }}>&nbsp;•&nbsp;</Typography>
              <Link
                href="https://www.skipify.com/us/privacy-policy/"
                target="_blank"
                sx={{ textDecoration: "none", mt: "auto", mb: "auto" }}
                color="text.primary"
                fontSize="10"
              >
                Privacy
              </Link>
            </Stack>
          </Stack>
        </Grid>
      </Stack>
    </footer>
  );
}
