import { InternalAxiosRequestConfig } from "axios";

import fp, { FingerPrintResponse } from "@services/fingerprint";

const DEVICE_ID_HEADER = "x-device-id";
const FP_REQUEST_ID_HEADER = "x-fp-request-id";
const LOOKUP_BY_FINGERPRINT_ENDPOINT = "/lookup_by_fingerprint";

const interceptWithFingerprint = async (requestConfig: InternalAxiosRequestConfig) => {
  // Allow real fpjs on stage and prod OR if forced specifically
  let fpResponse: FingerPrintResponse | undefined;
  try {
    //the sdk auto handles session caching and race conditions, only 1 request will be made
    fpResponse = await fp.getFingerprint();
  } catch (e) {
    console.error("[interceptWithFingerprint] failed with:", e);
  }

  if (fpResponse) {
    const { visitorId, requestId } = fpResponse;
    requestConfig.headers[DEVICE_ID_HEADER] = visitorId;
    // Check if the request is for lookup by fingerprint
    if (requestConfig.url && requestConfig.url.includes(LOOKUP_BY_FINGERPRINT_ENDPOINT)) {
      // Add fingerPrintJs requestId as header
      requestConfig.headers[FP_REQUEST_ID_HEADER] = requestId;
    }
  }

  return requestConfig;
};

export default interceptWithFingerprint;
